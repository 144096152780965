<template>
	<div class="index">
		<!-- 回到顶部 -->
		<goTop></goTop>
		<transition name="fade">
			<div class="max-box" v-show="show">
				<!-- 背景图片 -->
				<div class="bg">
					<img src="@/assets/img/index/bg.png">
					<!-- 背景图 文字部分 -->
					<div class="details">
						<h1 class="animate__animated animate__bounceIn">技术提升业务能力</h1>
						<h1 class="animate__animated animate__bounceIn">数据赋能实战价值</h1>
						<!-- 显示的省略号 -->
						<div class="more">
							<p class="animate__animated animate__bounceIn">
								...........................................
							</p>
							<p class="animate__animated animate__bounceIn">
								...........................................
							</p>
							<p class="animate__animated animate__bounceIn">
								...........................................
							</p>
						</div>
						<p class="animate__animated animate__bounceIn">源于技术&nbsp;&nbsp;终于服务</p>
					</div>
				</div>
				<!-- 中间部分内容 -->
				<main>
					<!-- 公司动态 -->
					<div class="dongtai">
						<div class="box">
							<div class="title clearfix">
								<h2>公司动态</h2>
								<h2>Company dynamics</h2>
							</div>
							<div class="content">
								
								
								
								
								
								
								<div>
								    <div style="width:100%;height: 100%;">
								      <!-- 这里的标签名称要和main.js文件中定义的组件名称保持一致 -->
								      <happy-scroll color="#09419B" size="3" style="width: 100%;padding-bottom: 68px;">
								        <!-- 内层盒子——内容区 -->
								        <div class="con" style="width: 100% !important;">
								          <div class="box clearfix" style="white-space: nowrap;">
								          	<div class="item" style="display: inline-block;width: 390px !important;height: 244px !important;">
								          		<img src="@/assets/img/index/dongtai-01.png" style="width: 100%;height: 272px;">
								          		<p style="display: block;font-size: 20px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;margin-top: 28px;">国网能源大数据平台</p>
								          		<span style="display: block;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-top: 17px;">2021-12-01</span>
								          	</div>
											<div class="item" style="display: inline-block;width: 390px !important;height: 244px !important;margin-right: 30px;">
												<img src="@/assets/img/index/dongtai-02.jpg" style="width: 390px;height: 272px;">
												<p style="display: block;font-size: 20px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;margin-top: 28px;">浙江边防总站线上对抗赛</p>
												<span style="display: block;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-top: 17px;">2021-03-16</span>
											</div>
											<div class="item" style="display: inline-block;width: 390px !important;height: 244px !important;margin-right: 30px;">
												<img src="@/assets/img/index/dongtai-03.jpg" style="width: 100%;height: 272px;">
												<p style="display: block;font-size: 20px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;margin-top: 28px;">杭州亚运出入境保障分析研判系统</p>
												<span style="display: block;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-top: 17px;">2022-04-07</span>
											</div>
											
											<!-- 动态中间部分实例开始，可删除 -->
											<!-- <div class="item" style="display: inline-block;width: 390px !important;height: 244px !important;margin-right: 30px;" v-for="n in 7">
												<img src="@/assets/img/index/dongtai-02.png" style="width: 100%;height: 272px;">
												<p style="display: block;font-size: 20px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;margin-top: 28px;">国网能源大数据平台</p>
												<span style="display: block;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-top: 17px;">2021-12-01</span>
											</div> -->
											<!-- 动态结束部分实例，可删除 -->
											
								          </div>
								        </div>
								      </happy-scroll>
									  <!-- 灰色滚动条 -->
									  <div class="scroll" style="height: 3px;background: #E5E5E5;margin-top: -3px;"></div>
								    </div>
								  </div>
							</div>
							
							
						</div>
					</div>
					<!-- 左侧省略号图片 -->
					<div class="img-left">
						<img src="@/assets/img/more.png">
					</div>
					<!-- 应用场景 -->
					<div class="changjing">
						<div class="box-left">
							<div class="title">
								<h2>应用场景</h2>
								<h2>Company dynamics</h2>
							</div>
							<div class="content">
								<div class="box" v-for="(cjitem,cjindex) in cj.data" @click="toggle(cjindex)">
									<el-row type="flex" class="row-bg" :class="cj.cjindex==cjindex?'act':'def'">
										<el-col :span="6">
											<div class="grid-content bg-purple">
												<img :src="cjitem.imgurl_act" v-if="cj.cjindex==cjindex">
												<img :src="cjitem.imgurl" v-else>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple-light">
												<h1>{{cjitem.title}}</h1>
												<p>{{cjitem.section}}</p>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple">
												<img src="../assets/img/index/right.png" v-if="cj.cjindex==cjindex">
											</div>
										</el-col>
									</el-row>
								</div>

							</div>
				
						</div>
						<!-- 应用场景左下角省略号 -->
						<div class="more-web">
							<img src="@/assets/img/more-web.png" >
						</div>
					</div>
				
					<!-- 右侧省略号图 -->
					<div class="more">
						<img src="@/assets/img/more.png">
					</div>
				
					<!-- 右侧悬浮图 -->
					<div class="box-right" v-show="isRightShow">
						<img :src="cj.right.imgurl">
						<div class="details">
							<h1 class="animate__animated animate__fadeInDown">{{cj.right.title}}</h1>
							<p class="animate__animated animate__fadeInDown">{{cj.right.section}}</p>
						</div>
					</div>
				
				
				
					<!-- 成功案例 -->
					<div class="anli">
						<div class="box">
							<div class="title">
								<h2>成功案例</h2>
								<h2>success case</h2>
							</div>
							<div class="content">
								<el-row :gutter="20">
									<el-col :span="10">
										<div class="grid-content bg-purple">
											<img src="@/assets/img/index/anli-01.png">
											<h1>浙江总站大数据分析研判系统</h1>
											<p>
												整个平台架构采用分层解耦的思想来设计，底端允许接入关系性数据库、非关系性数据库、Excel、文本文件等多种数据源。计算平台采用Hadoop和Spark大数据计算引擎，构成强有力的大数据计算中间件。部分简单计算，采用内置计算引擎来实现。采用高性能的Redis集群和MySql集群，进行计算结果的存储与分发。为应用层提供数据支持，业务应用可通过平台Web页面配置和查询计算结果，也可通过API进行统一的外接处理。
											</p>
										</div>
									</el-col>
									<el-col :span="10">
										<div class="grid-content bg-purple">
											<img src="@/assets/img/index/anli-02.png">
											<h1>国家移民局大数据协作平台</h1>
											<p>
												贯彻2020年全国移民管理工作会议精神，全面落实许副部长“加快科技信息基础骨干和重大应用项目建设，着力提升移民管理现代化水平”的指示要求，推动实施移民管理机构和省级公安机关的数据共享应用，建设数据分析协作平台，构建统一调度、中心研判、全国协作的移民管理研判新模
											</p>
										</div>
									</el-col>
									<el-col :span="10">
										<div class="grid-content bg-purple">
											<img src="@/assets/img/index/anli-03.png">
											<h1>浙江边检智慧消息结算平台</h1>
											<p>
												是在浙边检云平台的IT基础设施之上，构建的一套综合管理平台，运用物联网、云计算、大数据、通信技术等多种先进技术手段，在信息化建设方面构建统一的组织管理协调架构、业务管理平台和对外服务平台，为机关管理者以及下属单位提供创新管理与日常运营服务。
											</p>
										</div>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="10">
										<div class="grid-content bg-purple">
											<img src="@/assets/img/index/anli-04.png">
											<h1>浙江总站实战大练兵平台系统</h1>
											<p>
												基于学习强国的理念，加强执法学习，推动文明执法，加强执法力度；开展每日练习、挑战答题，线上对抗等多元化方式，提高学习的趣味性、挑战性。
											</p>
										</div>
									</el-col>
									<el-col :span="10">
										<div class="grid-content bg-purple">
											<img src="@/assets/img/index/anli-05.jpg">
											<h1>浙江省能源大数据中心</h1>
											<p>
												浙江省在线监测数字化管理平台的开发，为用户提供集中化管理的基础上，可实现将用户内部不同组织的局域网系统进行整合，使得管理更加统一透明高效。同时数字化管理平台可以服务于治理端、管理端、投资端，激发整个社会对在线监测投资的热情，让清洁能源的占比不断提升，助力实现‘碳达峰、碳中和’目标。
											</p>
										</div>
									</el-col>
									<el-col :span="10">
										<div class="grid-content bg-purple">
											<img src="@/assets/img/index/anli-06.png">
											<h1>山东省齐河县政务服务网</h1>
											<p>
												“爱山东”APP，通过对接山东省齐河县政务服务网，双向同步办理行政审批事项以及其它特色应用。在满足普通市民移动办理政府事项的同时，也为政府单位优化审批流程，积累大数据，为领导决策提供一手数据。
											</p>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</main>
				
			
				  
				  
			</div>
		</transition>
	</div>
</template>

<script>
	// 回到顶部
	import goTop from '@/components/goTop.vue'
	export default {
		name: "Index",
		components:{
			goTop
		},
		data() {
			return {
				show:false,
				// 应用场景默认选中index
				cjindex:0,
				cj:{
					cjindex:0,
					// 右侧默认展示数据
					right:{
						imgurl:require('@/assets/img/index/cj-01.png'),
						// 标题
						title:'疫情防控',
						// 段落
						section:`以大量数据为基础，对基于源定位和社交方式对于人员进行筛查，确认经过、接触、密接等信息；同时对于物品信息流动、接触等不同的画像标准进行筛查，为疫情防控提供帮助。`,
					},
					data:[{
						// 默认编号图标
						imgurl:require('@/assets/img/index/01.png'),
						// 选中时的编号图标
						imgurl_act:require('@/assets/img/index/01-act.png'),
						// 标题
						title:'疫情防控',
						// 段落
						section:'以大量数据为基础，对基于源定位和社交方式对于人员进行筛查，确认经过、接触、密接等信息；同时对于物品信息流动、接触等不同的画像标准进行筛查，为疫情防控提供帮助。',
						// 右侧展示
						right:{
							imgurl:require('@/assets/img/index/cj-01.png'),
							// 标题
							title:'疫情防控',
							// 段落
							section:`以大量数据为基础，对基于源定位和社交方式对于人员进行筛查，确认经过、接触、密接等信息；同时对于物品信息流动、接触等不同的画像标准进行筛查，为疫情防控提供帮助。`,
						}
					},{
						// 默认编号图标
						imgurl:require('@/assets/img/index/02.png'),
						// 选中时的编号图标
						imgurl_act:require('@/assets/img/index/02-act.png'),
						// 标题
						title:'人员预警',
						// 段落
						section:'通过公安网数据对接，对于存在安全、暴力、前科的人员进行筛查和社交、活动轨迹的多重画像进行预警',
						// 右侧展示
						right:{
							imgurl:require('@/assets/img/index/cj-02.png'),
							// 标题
							title:'人员预警',
							// 段落
							section:`通过公安网数据对接，对于存在安全、暴力、前科的人员进行筛查和社交、活动轨迹的多重画像进行预警`,
						}
					},{
						// 默认编号图标
						imgurl:require('@/assets/img/index/03.png'),
						// 选中时的编号图标
						imgurl_act:require('@/assets/img/index/03-act.png'),
						// 标题
						title:'船舶预警',
						// 段落
						section:'依据船舶定位系统，对轨迹，航线、停靠时间、交集等结合人员、货物综合判断预警信息',
						// 右侧展示
						right:{
							imgurl:require('@/assets/img/index/cj-03.png'),
							// 标题
							title:'船舶预警',
							// 段落
							section:`依据船舶定位系统，对轨迹，航线、停靠时间、交集等结合人员、货物综合判断预警信息`,
						}
					},{
						// 默认编号图标
						imgurl:require('@/assets/img/index/04.png'),
						// 选中时的编号图标
						imgurl_act:require('@/assets/img/index/04-act.png'),
						// 标题
						title:'安保协同',
						// 段落
						section:'基于人员预警信息，结合数据分析研判，对于安保工作提供更有力的技术支持',
						// 右侧展示
						right:{
							imgurl:require('@/assets/img/index/cj-04.png'),
							// 标题
							title:'安保协同',
							// 段落
							section:`基于人员预警信息，结合数据分析研判，对于安保工作提供更有力的技术支持`,
						}
					},{
						// 默认编号图标
						imgurl:require('@/assets/img/index/05.png'),
						// 选中时的编号图标
						imgurl_act:require('@/assets/img/index/05-act.png'),
						// 标题
						title:'能源分布',
						// 段落
						section:'通过遥感监测技术，对于能源分布、管理、预产能、消耗等综合性数据支撑',
						// 右侧展示
						right:{
							imgurl:require('@/assets/img/index/cj-05.png'),
							// 标题
							title:'能源分布',
							// 段落
							section:`通过遥感监测技术，对于能源分布、管理、预产能、消耗等综合性数据支撑`,
						}
					}
					]
				},
				// 应用场景，切换文字的开关
				isRightShow:true,
				// 成功案例
				al:[{
					imgurl:require('@/assets/img/index/anli-01.png'),
					title:'浙江总站大数据分析研判系统',
					section:'整个平台架构采用分层解耦的思想来设计，底端允许接入关系性数据库、非关系性数据库、Excel、文本文件等多种数据源。计算平台采用Hadoop和Spark大数据计算引擎，构成强有力的大数据计算中间件。部分简单计算，采用内置计算引擎来实现。采用高性能的Redis集群和MySql集群，进行计算结果的存储与分发。为应用层提供数据支持，业务应用可通过平台Web页面配置和查询计算结果，也可通过API进行统一的外接处理。'
				},{
					imgurl:require('@/assets/img/index/anli-02.png'),
					title:'国家移民局大数据协作平台',
					section:'贯彻2020年全国移民管理工作会议精神，全面落实许副部长“加快科技信息基础骨干和重大应用项目建设，着力提升移民管理现代化水平”的指示要求，推动实施移民管理机构和省级公安机关的数据共享应用，建设数据分析协作平台，构建统一调度、中心研判、全国协作的移民管理研判新模。'
				},{
					imgurl:require('@/assets/img/index/anli-03.png'),
					title:'浙江边检智慧消息结算平台',
					section:'是在浙边检云平台的IT基础设施之上，构建的一套综合管理平台，运用物联网、云计算、大数据、通信技术等多种先进技术手段，在信息化建设方面构建统一的组织管理协调架构、业务管理平台和对外服务平台，为机关管理者以及下属单位提供创新管理与日常运营服务。'
				},{
					imgurl:require('@/assets/img/index/anli-04.png'),
					title:'浙江总站实战大练兵平台系统',
					section:'基于学习强国的理念，加强执法学习，推动文明执法，加强执法力度；开展每日练习、挑战答题，线上对抗等多元化方式，提高学习的趣味性、挑战性。'
				},{
					imgurl:require('@/assets/img/index/anli-05.jpg'),
					title:'浙江省能源大数据中心',
					section:'浙江省在线监测数字化管理平台的开发，为用户提供集中化管理的基础上，可实现将用户内部不同组织的局域网系统进行整合，使得管理更加统一透明高效。同时数字化管理平台可以服务于治理端、管理端、投资端，激发整个社会对在线监测投资的热情，让清洁能源的占比不断提升，助力实现‘碳达峰、碳中和’目标。'
				},{
					imgurl:require('@/assets/img/index/anli-06.png'),
					title:'山东省齐河县政务服务网',
					section:'“爱山东”APP，通过对接山东省齐河县政务服务网，双向同步办理行政审批事项以及其它特色应用。在满足普通市民移动办理政府事项的同时，也为政府单位优化审批流程，积累大数据，为领导决策提供一手数据。'
				}]
			};
		},
		methods: {
			// 应用场景内容切换
			toggle(id) {
				this.cj.cjindex=id;
				this.cj.right=this.cj.data[id].right;
				console.log(this.cjindex);
				this.isRightShow=false;
				var timer=setTimeout(()=>{
					this.isRightShow=true;
					clearTimeout(timer)
				},300)
			}
		},
		mounted() {
			this.show=true;
			
		}

	};
</script>
<style lang="scss" scoped>
	/deep/ .happy-scroll-container{
		width: 100% !important;
		height: 100% !important;
	}
	
	
	.left {
		display: inline-block !important;
		float: left !important;
	}
	
	.clearfix::after {
		display: block;
		clear: both;
		content: '';
		font-size: 0px;
		height: 0px;
		visibility: hidden;
	}
	
	.clearfix {
		display: block;
		*zoom: 1;
	}
	
	.fade-enter-active, .fade-leave-active {
	    transition: opacity 2s
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
	    opacity: 0
	}
	
	
	.index{
		// 因为需要置顶，需要减去导航条的高度
		margin-top: -70.75px;
	}
	/*背景图*/

	.bg {
		position: relative;

		img {
			width: 100%;
			height: 100vh;
		}

		/* 背景图文字 */
		.details {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 509px;
			transform: translate(-50%, -50%);
			z-index: 1;

			h1 {
				color: #fff;
				font-size: 60px;
				margin: 0;
			}

			h1:first-child {
				font-weight: normal;
				margin-bottom: 29px;
			}

			h1:nth-child(2) {
				font-weight: bold;
				margin-bottom: 23px;
			}

			p {
				font-size: 30px;
				color: #fff;
				text-align: center;
			}
		}

		/* 省略号 */
		.more {
			
			// width: 417px;
			text-align: center;
			margin:  0 auto;
			margin-bottom: 24px;
			color: #fff;
			height: 20px;
			p{
				// width: 417px !important;
				height: 5px;
				font-size: 12px;
				letter-spacing: 5px;
				line-height: 1px;
			}
		}
	}
	
	// 应用场景文字颜色切换
	.def{
		h1{
			color: #333 !important;
		}
		p{
			color: #666 !important;
		}
	}
	.act{
		h1{
			color: #09419B !important;
		}
		p{
			color: #194FA8 !important;
		}
	}
	
	// 中间部分
	main {
		position: relative;


		// 左侧省略号
		.img-left {
			width: 177px;
			height: 409px;
			position: absolute;
			left: 0px;
			top: 523px;
			z-index: 1 !important;

			img {
				width: 100%;
				height: 100%;
			}
		}


		/* 公司动态 */
		.dongtai {
			width: 100%;
			height: 743px;
			box-sizing: border-box;
			
			.item{
				margin-right: 40px;
			}
			
			.item:last-child{
				margin-right: 200px;
			}

			.box {
				max-width: 1441px;
				margin: 0 auto;
				margin-top: 101px;

				.title {
					position: relative;
					height: 62px;

					h2 {
						// width: 142px;
						height: 34px;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						position: absolute;
						top: 0;
						z-index: 10;

					}

					h2:nth-child(2) {
						height: 28px;
						font-size: 36px;
						font-family: Verdana;
						font-weight: 400;
						color: #F3F3F3;
						position: absolute;
						top: 27px;
						z-index: 1 !important;
						text-transform: uppercase;
					}
				}

				.content {
					margin-top: -20px;

				}
			}

			.bottom {
				position: relative;

				.line-none {
					width: 100%;
					max-width: 1441px;
					position: absolute;
					margin-top: 88px;
					left: 50%;
					transform: translateX(-50%);
					height: 3px;
					background: #E5E5E5;
				}

				.line-act {
					width: 100%;
					max-width: 400px;
					z-index: 2;
					position: absolute;
					top: 88px;
					left: 0;
					height: 3px;
					background: #09419B;
				}

			}
		}

		/* 应用场景 */
		.changjing {
			position: relative;
			z-index: 100 !important;
			width: 1555px;
			height: 1000px;
			background: #F2F2F2;
			margin-top: 23px;

			.box-left {
				display: inline-block;
				padding: 122px 240px;

				.title {
					position: relative;
					margin-bottom: 66px;

					h2 {
						font-size: 36px;
					}

					h2:nth-child(2) {
						height: 28px;
						font-size: 36px;
						font-family: Verdana;
						font-weight: 400;
						color: #333333;
						opacity: 0.05;
						position: absolute;
						top: 60%;
						text-transform: uppercase;
					}
				}

				.content {
					.box{
						.el-row{
							margin: 67px 0px !important;
						}
					}
					.row-bg {
						margin-bottom: 67px;
						cursor: pointer;
						.el-col:first-child {
							width: 78px !important;
							height: 62px !important;

							.grid-content {
								width: 100%;

								img {
									width: 78px !important;
									height: 62px !important;
								}
							}
						}

						.el-col:nth-child(2) {
							width: 283px;
							margin-left: 66px;
							margin-right: 75px;
						}

						.el-col:nth-child(3) {
							width: 81px !important;
							height: 69px !important;

							.grid-content {
								width: 100%;

								img {
									width: 81px !important;
									height: 69px !important;
								}
							}
						}

						.grid-content {
							h1 {
								height: 25px;
								font-size: 26px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								margin: 0px 0px 14px 0px;
							}

							p {
								// height: 34px;
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								margin: 0;
								
								display: -webkit-box;
								/* -webkit-box-orient: vertical; */
								/*! autoprefixer: off */
								-webkit-box-orient: vertical;
								/* autoprefixer: on */
								//超出多少行显示省略号
								-webkit-line-clamp: 2;
								overflow: hidden;
							}
						}
					}

					.row-bg:first-child {
						margin-top: 92px;
					}
				}
			}
			// 应用场景省略号
			.more-web{
				width: 178px;
				height: 212px;
				position: absolute;
				bottom: 26px;
				img{
					width: 100%;
				}
			}
		}




		// 右侧省略号图
		.more {
			position: absolute;
			display: inline-block;
			top: 816px;
			left: 1555px;

			img {
				width: 200px;
				height: 200px;
			}
		}


		// 右侧悬浮图（应用场景）
		.box-right {
			width: 53%;
			position: absolute;
			z-index: 100;
			top: 990px;
			right: 0;

			img {
				width: 100%;
				height: 600px;
			}

			.details {
				background: #194FA8;
				padding: 55px 88px;

				h1 {
					height: 29px;
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #FFFFFF;
					margin: 0px 0px 26px 0px;
				}

				p {
					line-height: 1.5em;
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}

		// 案例
		.anli {
			max-width: 1441px;
			margin: 0 auto 57px;
			.box {
				max-width: 1441px;
				margin: 0 auto;
				margin-top: 121px;

				.title {
					position: relative;
					margin-bottom: 66px;
					height: 62px;
				
					h2 {
						// width: 142px;
						height: 34px;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						position: absolute;
						top: 0;
						z-index: 10;
				
					}
				
					h2:nth-child(2) {
						height: 28px;
						font-size: 36px;
						font-family: Verdana;
						font-weight: 400;
						color: #F3F3F3;
						position: absolute;
						top: 27px;
						z-index: 1 !important;
						text-transform: uppercase;
					}
				}

				.content {
					.el-col {
						width: 466px !important;
						margin-bottom: 37px;
						cursor: pointer;
						.grid-content {
							width: 466px;

							img {
								width: 100%;
								width: 466px;
								height: 300px;
							}
							h1{
								height: 20px;
								font-size: 20px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								color: #333333;
								line-height: 36px;
								margin: 21px 2px 16px 2px;
							}
							p{
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #999999;
								line-height: 20px;
								margin: 0px 2px;
								
								display: -webkit-box;
								/* -webkit-box-orient: vertical; */
								/*! autoprefixer: off */
								-webkit-box-orient: vertical;
								/* autoprefixer: on */
								//超出多少行显示省略号
								-webkit-line-clamp: 1;
								overflow: hidden;
							}
						}
					}

					.el-col:nth-child(2) {
						margin: 0px 21px;
					}
				}
			}


		}
	}
</style>
