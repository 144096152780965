<template>
	<div id="goTop">
		 <div class="goTop" v-show="goTopShow" @click="goTop">
			 <i class="glyphicon glyphicon-arrow-up"></i>
		 </div>
	 </div>
</template>

<script>
	export default {
	     name: "goTop",
	     data() {
	         return {
	             scrollTop: 0,
	             goTopShow: false
	         };
	     },
	     watch: {
	         scrollTop(val) {
	             if (this.scrollTop > 500) {
	                 this.goTopShow = true;
	             } else {
	                 this.goTopShow = false;
	             }
	         }
	     },
	     methods: {
	         handleScroll() {
	             this.scrollTop =
	                 window.pageYOffset ||
	                 document.documentElement.scrollTop ||
	                 document.body.scrollTop;
	             if (this.scrollTop > 500) {
	                 this.goTopShow = true;
	             }
	         },
	         goTop() {
	             let timer = null,
	                 _that = this;
	             cancelAnimationFrame(timer);
	             timer = requestAnimationFrame(function fn() {
	                 if (_that.scrollTop > 0) {
	                     _that.scrollTop -= 250;
	                     document.body.scrollTop = document.documentElement.scrollTop =
	                         _that.scrollTop;
	                     timer = requestAnimationFrame(fn);
	                 } else {
	                     cancelAnimationFrame(timer);
	                     _that.goTopShow = false;
	                 }
	             });
	         }
	     },
	     mounted() {
	         window.addEventListener("scroll", this.handleScroll);
	     },
	     destroyed() {
	         window.removeEventListener("scroll", this.handleScroll);
	     }
	 };
</script>

<style lang="scss" scoped>
	.goTop {
	     position: fixed;
	     right: 80px;
	     bottom: 200px;
	     width: 50px;
	     height: 50px;
	     border-radius: 50%;
	     background: #fff;
	     padding: 13px;
	     cursor: pointer;
	     box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
		 z-index: 10000;
		 i{
			 color: #409EFF;
		 }
	 }
	 .goTop:hover{
		 background: rgba(122,158,196,.8);
	 }
	 .goTop:hover .glyphicon {
	     color: rgba(51, 153, 255, 1);
	 }
	 .glyphicon {
	     font-size: 22px;
	     color: rgba(51, 153, 255, 0.8);
	 }
</style>
